import {useState} from 'react'

//import firebase services
import {auth} from '../firebase/config'
import {signInWithEmailAndPassword} from 'firebase/auth'
import {useAuthContext} from './useAuthContext'

// Custom useLogin hook
export function useLogin() {
    const [error, setError] = useState(null)
    const {dispatch} = useAuthContext()
    const login = (email, password) => {
        setError(null)
        signInWithEmailAndPassword(auth, email, password)
        .then(res => {
            dispatch({type: 'LOGIN', payload: res.user})
        })
        .catch(e => {
            setError(e.message)
        })
    }

    return {error, login}

}