import React, { useEffect, useState } from 'react';
import './Favorites.css';
import Navbar from '../components/Navbar';
import BottomNavbar from '../components/BottomNavbar';
import PerfumeFavoriteCard from '../components/PerfumeFavoriteCard';

// import custom hooks
import useCollection from '../hooks/useCollection'
import {useAuthContext} from '../hooks/useAuthContext'
import {useRemoveDuplicates} from '../hooks/useRemoveDuplicates'

export default function Favorites() {
  return (
    <div className="Favorites">
      <Navbar />  
      <BottomNavbar />
    </div>
  )
}


