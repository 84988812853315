import {useState} from 'react'
//import firebase services
import {auth, storage} from '../firebase/config'
import {createUserWithEmailAndPassword, updateProfile} from 'firebase/auth'
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {useAuthContext} from './useAuthContext'

// define the custom hook to signup a user
export const useSignup = () => {
    const [error, setError] = useState(null)
    const {dispatch} = useAuthContext()

    const signup = async (email, password, imageFile=null) => {
        setError(null)
        try {
            // signup
            const res = await createUserWithEmailAndPassword(auth, email, password)
            if(res){
                console.log('The user below was created successfully')
                console.log(res.user)
            }
            if(!res){
                throw new Error('Could not complet signup');
            }

            //upload profile image if image uploaded
            if(imageFile){
            const uploadPath = `profileImages/${res.user.uid}/${imageFile.name}`
            const storageRef = await ref(storage,uploadPath);
            await uploadBytes(storageRef, imageFile);
            const imageURL = await getDownloadURL(storageRef);

            //update user profile with Avatar image
            try {
                await updateProfile(auth.currentUser, 
                    {photoURL:imageURL});
                console.log(res.user);
            }
            catch(e){
                console.log(e.message)
            }
            }
            // dispatch login action
            dispatch({type: 'LOGIN', payload: res.user})
        }
        catch(e) {
            setError(e.message)
        }

    }

    return {error, signup}
}
