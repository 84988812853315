import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import {getStorage} from 'firebase/storage'
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyAekq083f6IDScc5rfn7EXWwWwmIGqzdkw",
  authDomain: "standzero-ac74c.firebaseapp.com",
  projectId: "standzero-ac74c",
  storageBucket: "standzero-ac74c.appspot.com",
  messagingSenderId: "479394062261",
  appId: "1:479394062261:web:dc603dc1fa8d7ba8aa03fc",
  measurementId: "G-H580Q05P3X"
};



  // initialize firebase

  const firebaseApp = initializeApp(firebaseConfig);

  
  // initialize db service
  
  const db = getFirestore(firebaseApp)

  //initialize storage service 

  const storage = getStorage(firebaseApp)

  // initialize firebase auth service

  const auth = getAuth(firebaseApp)
  
  // initialize google analytics service

  const analytics = getAnalytics(firebaseApp);

  export {db, auth, storage, analytics}