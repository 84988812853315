import React, {useState, useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Calendar from './pages/Calendar.js';
import SignUp from './pages/SignUp.js';
import Login from './pages/Login.js';
import Search from './pages/Search.js';
import Favorites from './pages/Favorites.js';
import Website from './pages/Website';
import {useAuthContext} from './hooks/useAuthContext';

function App() {

const {user, authIsReady} = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
      <BrowserRouter>        
        <Routes>
          <Route path="/" element={<Website />} />
          {user && <Route path="/calendar" element={<Calendar />} />}
          {!user && <Route path="/calendar" element={<Navigate to="/login" />} />}
          {user && <Route path="/search" element={<Search />} />}
          {!user && <Route path="/search" element={<Navigate to="/login" />} />}
          {user && <Route path="/teams" element={<Favorites />} />}
          {!user && <Route path="/teams" element={<Navigate to="/login" />} />}
          {!user && <Route path="/signup" element={<SignUp />} />}
          {user && <Route path="/signup" element={<Navigate to="/calendar" />} />}
          {!user && <Route path="/login" element={<Login />} />}
          {user && <Route path="/login" element={<Navigate to="/calendar" />} />}
        </Routes>
      </BrowserRouter>
      )}
    </div>
  );
}

export default App;
