import { useNavigate } from 'react-router-dom';
import './Calendar.css'
import Navbar from '../components/Navbar.js';
import {useAuthContext} from '../hooks/useAuthContext'
import BottomNavbar from '../components/BottomNavbar'
import Button from '@mui/material/Button';

export default function Calendar() {
  // get the user from the auth context
  const {user} = useAuthContext();
  const navigate = useNavigate();

  return (
    <div className="Calendar">
      <Navbar />
      <div className="Calendar-content-page">
        <div className="Calendar-content-page-text">
          <p>Jouw favoriete ploegen spelen niet op deze dag</p>
        </div>
        <div className="Calendar-content-page-addTeamButton">
          <Button variant="outlined" onClick={()=>{navigate("/search")}}>+ Voeg een team toe</Button>
        </div>
      </div>
      <BottomNavbar />
    </div>
  )
}
