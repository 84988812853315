import React, {useState} from 'react';
import './PriceCard.css'

// friebase imports
import {db} from '../firebase/config'
import {collection, addDoc} from 'firebase/firestore'
import {useAuthContext} from '../hooks/useAuthContext'

export default function PriceCard({title, price, bullet1, bullet2, bullet3, bullet4, bullet5}) {

const [orderFormOpen, setOrderFormOpen] = useState(false);
const [orderSubmitted, setOrderSubmitted] = useState(false); 
const [emailInput, setEmailInput] = useState(""); 
const [cardContentOpen, setCardContentOpen] = useState(true); 

const handleOrderClick = () => {
    setOrderFormOpen(true);
    setCardContentOpen(false);
}

const handleEmailInputChange = (e) => {
    setEmailInput(e.target.value);
}

const handleEmailSubmit = async () => {
    setOrderSubmitted(true);
    setOrderFormOpen(false)
    const ref = collection(db, 'orderLeads');
    const orderLead = {email: emailInput, orderRequest:title};
    await addDoc(ref, orderLead);
    setEmailInput("");
}
 

  return (
    <div className="PriceCard">
        <div className="card text-center">
            {cardContentOpen && (<div className="card-content">
                <div className="title">
                    <h2>{title}</h2>
                </div>
                <div className="price">
                    <h4><sup>€</sup>{price}<span className="smallMonth">month</span></h4>
                </div>
                <div className="option">
                    <ul>
                        <li> <i className="fa fa-check" aria-hidden="true"></i>{bullet1}</li>
                        <li> <i className="fa fa-check" aria-hidden="true"></i>{bullet2}</li>
                    </ul>
                    <a className="orderButton" onClick={handleOrderClick}>Order Now</a>
                </div>
            </div>)}
            {orderFormOpen && (<div className="subscribeSection">
                <p>Thanks for showing interest in our {title} offering. Currently we aren't live yet. If you want to get informed when we officaly launch, leave your e-mail address below.</p>
                <form className="form-inline" id="premiumInterest">
                    <label className="sr-only" htmlFor="inlineFormInputName2">email</label>
                    <input type="text" name="email" className="form-control mb-2 mr-sm-2" id="inlineFormEmail" placeholder="jane.doe@gmail.com" onChange={handleEmailInputChange} />
                    <button type="submit" className="btn btn-primary mb-2 submitEmailButton" onClick={handleEmailSubmit}>Submit</button>
                </form>
            </div>)}
            {orderSubmitted && (<div className="subscribedSection">
                <p>Thanks for leaving your email address.<br/>
                We'll keep you informed on our upcoming launch!</p>
                <i className="fa fa-rocket rocket" aria-hidden="true"></i>
            </div>)}
        </div>
    </div>
  )
}
