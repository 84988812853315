import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import UpcomingIcon from '@mui/icons-material/Upcoming';


export default function BottomNavbar() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const navigate = useNavigate();


  return (
    <Box sx={{ pb: 7 }} ref={ref} >
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Kalender" icon={<RestoreIcon />} onClick={()=>{navigate("/calendar")}} />
          <BottomNavigationAction label="Zoeken" icon={<UpcomingIcon />} onClick={()=>{navigate("/search")}} />
          <BottomNavigationAction label="Ploegen" icon={<FavoriteIcon />} onClick={()=>{navigate("/teams")}} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}