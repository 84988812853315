import React, {useEffect, useState} from 'react'
import './Search.css'
import Navbar from '../components/Navbar'
import BottomNavbar from '../components/BottomNavbar'

// Material-UI component imports
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// custom hooks
import {useInputState} from '../hooks/useInputState';
import {useAuthContext} from '../hooks/useAuthContext';

// firebase imports
import {db} from '../firebase/config'
import {collection, addDoc} from 'firebase/firestore'
import OccasionMoodInputRow from '../components/OccasionMoodInputRow';

export default function Search() {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="Search">
        <Navbar />
        <div className="Search-content">
          <Paper className="Search-content-searchbar">
            <InputBase
            className="Search-content-searchbar-input"
            placeholder="Zoek je favoriete team" 
            onChange={handleSearchChange}
            />
            <IconButton className="Search-content-searchbar-button">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <BottomNavbar />
    </div>
  )
}
