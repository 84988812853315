import React from 'react'
import "./Website.css"
import Chip from '@mui/material/Chip';
import WebsiteNavbar from "../components/WebsiteNavbar"
import PriceCard from "../components/PriceCard"
import liveStream from "../assets/LiveStream.png"
import camera from "../assets/Camera.png"
import rewatch from '../assets/Rewatch.png'
import stats from '../assets/Stats.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faLinkedin, faDribbble } from '@fortawesome/free-brands-svg-icons'

export default function Website() {
  return (
    <div className="Website">
        <WebsiteNavbar className="Website-websiteNavbar" />
        <div className="Website-content">
            <section className="introPage">
                <div className='introductionPage'>
                    <div className='titlePage'>
                        <div className="titlePageText">
                            <h1>StandZero</h1>
                            <h2>LIVE AMATEUR VOETBAL</h2>
                            <h3>
                            VOLG ALLE WEDSTRIJDEN VAN JE PLAATSELIJKE TEAM, LIVE OF UITGESTELD, WAAR EN WANNEER JE MAAR WIL
                            </h3><br/>
                            <div className="intro-call-to-action">
                                <div className="joinButtonSection">
                                    <a href='#ourPricing' className="introButton">JOIN US</a>
                                </div>
                                <div className="socialMediaSection">
                                    <ul className="social-icons">
                                        <li><a className="facebook" href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                        <li><a className="twitter" href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                        <li><a className="dribbble" href="/"><FontAwesomeIcon icon={faDribbble} /></a></li>
                                        <li><a className="linkedin" href="/"><FontAwesomeIcon icon={faLinkedin} /></a></li>   
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </section>
            <section className="ourConcept" id="ourApproach">
                <div className="container descriptions">
                    <div className="row">
                        <div className="col-sm imageColumn">
                            <img src={liveStream} />
                        </div>
                        <div className="col-sm textColumn">
                            <div className="textArea">
                                <Chip label="Live-Streaming" sx={{backgroundColor: "rgb(99,202,221)", mb: "10px", color: 'white'}} />
                                <h2>Volg je favoriete team,<br/>van waar je maar wil</h2>
                                <p>Via onze live stream app, kan je je favoriete ploeg volgen van waar je maar wil. Hierdoor mis je geen enkel moment van je favoriete team of speler.</p>
                                <p>Stand Zero ondersteund zowel Airplay als Chromecast, hierdoor kan je de wedstrijden makkelijk laten afspelen op TV of een ander groot scherm met een draadloze connectie.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container descriptions">
                    <div className="row">
                        <div className="col-sm imageColumn reverse">
                            <img src={camera} />
                        </div>
                        <div className="col-sm textColumn reverse">
                            <div className="textArea">
                                <Chip label="AI Camera" sx={{backgroundColor: "rgb(35,30,86)", mb: "10px", color: 'white'}} />
                                <h2>Een compacte AI camera,<br/>die alle wedstrijden<br/>automatisch in beeld brengt</h2>
                                <p>Een draagbare, compacte, waterresistente AI camera die de wedstrijden van je favoriete team automatisch in beeld brengt en live-streamed.</p>
                                <p>De compacte StandZero camera maakt gebruik van een ingebouwde 4G/LTE modem om te connecteren met het internet en de wedstrijden te live streamen. Hierdoor is deze makkelijk verplaatsbaar en bruikbaar om ook de wedstrijden op verplaatsing perfect in beeld te brengen en te live-streamen.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container descriptions">
                    <div className="row">
                        <div className="col-sm imageColumn">
                            <img src={rewatch} />
                        </div>
                        <div className="col-sm textColumn">
                            <div className="textArea">
                                <Chip label="Herbekijken" sx={{backgroundColor: "rgb(237,23,117)", mb: "10px", color: 'white'}} />
                                <h2>Beelden van je team<br/>achteraf herbekijken</h2>
                                <p>Door de cursor op de stream tijdlijn te verplaatsen kan je bepaalde fases, of hele delen van de wedstrijd, herbekijken. Via speciale icoontjes op de tijdlijn worden mogelijks interessante fases aangeduid.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container descriptions">
                    <div className="row">
                        <div className="col-sm imageColumn reverse">
                            <img src={stats} />
                        </div>
                        <div className="col-sm textColumn reverse">
                            <div className="textArea">
                                <Chip label="Statistieken" sx={{backgroundColor: "rgb(78,80,162)", mb: "10px", color: 'white'}} />
                                <h2>Een overzicht van de wedstrijd statistieken live gegenereerd door AI</h2>
                                <p>Volg live hoe goed jouw team presteert aan de hand van enkele AI-gegenereerde statistieken zoals het aantal schoten, het aantal corners, het aantal overtredingen, de hoeveelheid balbezit en het aantal aanvallen overheen het veld.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pricing" id="ourPricing">
                <div className="container-fluid">
                    <div className="container">
                        <h5>Kies een plan dat best past bij je noden</h5>
                        <div className="row">
                            <PriceCard 
                            className="PriceCard"
                            title={"dagpas"}
                            price={"2"}
                            bullet1={"24u onbeperkte toegang"}
                            bullet2={"Alle functionaliteiten"}
                            />
                            <PriceCard 
                            className="PriceCard"
                            title={"Month-pass"}
                            price={"5"}
                            bullet1={"1 maand onbeperkte toegang"}
                            bullet2={"Alle functionaliteiten"}
                            />
                            <PriceCard 
                            className="PriceCard"
                            title={"Year-pass"}
                            price={"30"}
                            bullet1={"1 jaar onbeperkte toegang"}
                            bullet2={"Alle functionaliteiten"}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  )
}
